exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-acceptance-policy-tsx": () => import("./../../../src/pages/acceptance-policy.tsx" /* webpackChunkName: "component---src-pages-acceptance-policy-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-complaints-policy-tsx": () => import("./../../../src/pages/complaints-policy.tsx" /* webpackChunkName: "component---src-pages-complaints-policy-tsx" */),
  "component---src-pages-contact-support-tsx": () => import("./../../../src/pages/contact-support.tsx" /* webpackChunkName: "component---src-pages-contact-support-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-financial-services-tsx": () => import("./../../../src/pages/financial-services.tsx" /* webpackChunkName: "component---src-pages-financial-services-tsx" */),
  "component---src-pages-help-centre-tsx": () => import("./../../../src/pages/help-centre.tsx" /* webpackChunkName: "component---src-pages-help-centre-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-supported-currencies-tsx": () => import("./../../../src/pages/supported-currencies.tsx" /* webpackChunkName: "component---src-pages-supported-currencies-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-single-service-tsx": () => import("./../../../src/templates/single-service.tsx" /* webpackChunkName: "component---src-templates-single-service-tsx" */),
  "component---src-templates-single-solution-tsx": () => import("./../../../src/templates/single-solution.tsx" /* webpackChunkName: "component---src-templates-single-solution-tsx" */)
}

